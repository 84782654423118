<template>
<v-app>
  <v-container id="vote-record-detail" fluid tag="section">
    <v-row justify="center">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      <v-col cols="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="text-center text-h2 font-weight-medium">{{ meeting.urban_name }}</div>
            <div class="text-center text-h2 font-weight-medium">{{ meeting.title }}</div>
          </template>
          <v-col cols="12" class="text-center text-h2">{{ $t('vote.issueTitle') }}{{ issue.title }}</v-col>
          <v-col
            cols="12"
            class="text-center text-h2"
          >{{ TransDate(current_time) }}</v-col>
          <v-divider class="mx-4"></v-divider>
          <v-simple-table class="record_table py-2" v-for="(items, item_index) in result.positive_vote">
            <template v-slot:default>
              <thead class="positive_vote">
                <tr>
                  <th
                    class="text-center text-h3 pa-2 table-title"
                  >{{ $t('vote.Ranking') }}</th>
                  <th
                    class="text-center text-h3 pa-2"
                    v-for="(item, index) in items"
                  >{{ index + 5*item_index+1 }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center text-h3 pa-2 table-title"
                    :style="{width: '9%'}"
                  >{{ $t('vote.option') }}</td>
                  <template v-for="item in items">
                    <td
                      class="text-center text-h3 pa-2"
                    ><div>{{ item.option_name.split("_")[0] }}</div><div>{{ item.option_name.split("_")[1] }}</div></td>
                  </template>
                </tr>
                <tr>
                  <td
                    class="text-center text-h3 pa-2 table-title"
                  >{{ $t('vote.owners_count') }}</td>
                  <template v-for="item in items">
                    <td>
                      <v-row>
                        <v-col cols="6" class="text-center text-h3 td-left">{{ item.owner }}</v-col>
                        <v-col
                          cols="6"
                          class="text-center text-h3"
                        >{{ ((item.owner/owners.length)*100).toFixed(2) }}％</v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="text-center text-h3 pa-2">{{ $t('vote.land') }}</td>
                  <template v-for="item in items">
                    <td>
                      <v-row>
                        <v-col cols="6" class="text-center text-h3 td-left">{{ item.land.toFixed(2) }}</v-col>
                        <v-col
                          cols="6"
                          class="text-center text-h3"
                        >{{ ((item.land/land_area)*100).toFixed(2) }}％</v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td
                    class="text-center text-h3 pa-2 table-title"
                  >{{ $t('vote.building') }}</td>
                  <template v-for="item in items">
                    <td>
                      <v-row>
                        <v-col cols="6" class="text-center text-h3 td-left">{{ item.building.toFixed(2) }}</v-col>
                        <v-col
                          cols="6"
                          class="text-center text-h3"
                        >{{ ((item.building/building_area)*100).toFixed(2) }}％</v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="record_table py-2" v-for="(items, item_index) in result.alternates_vote">
            <template v-slot:default>
              <thead class="alternates_vote">
                <tr>
                  <th
                    class="text-center text-h3 pa-2 table-title"
                  >{{ $t('vote.Ranking') }}</th>
                  <th
                    class="text-center text-h3 pa-2"
                    v-for="(item, index) in items"
                  >
                    <span class="pa-0">{{ positive_count+index + 5*item_index+1 }}</span>
                    <span class="pa-0">({{ $t('vote.alternates') }})</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="text-center text-h3 pa-2 table-title"
                    :style="{width: '9%'}"
                  >{{ $t('vote.option') }}</td>
                  <template v-for="item in items">
                    <td
                      class="text-center text-h3 pa-2"
                    ><div>{{ item.option_name.split("_")[0] }}</div><div>{{ item.option_name.split("_")[1] }}</div></td>
                  </template>
                </tr>
                <tr>
                  <td
                    class="text-center text-h3 pa-2 table-title"
                  >{{ $t('vote.owners_count') }}</td>
                  <template v-for="item in items">
                    <td>
                      <v-row>
                        <v-col cols="6" class="text-center text-h3 td-left">{{ item.owner }}</v-col>
                        <v-col
                          cols="6"
                          class="text-center text-h3"
                        >{{ ((item.owner/owners.length)*100).toFixed(2) }}％</v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td class="text-center text-h3 pa-2">{{ $t('vote.land') }}</td>
                  <template v-for="item in items">
                    <td>
                      <v-row>
                        <v-col cols="6" class="text-center text-h3 td-left">{{ item.land.toFixed(2) }}</v-col>
                        <v-col
                          cols="6"
                          class="text-center text-h3"
                        >{{ ((item.land/land_area)*100).toFixed(2) }}％</v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td
                    class="text-center text-h3 pa-2 table-title"
                  >{{ $t('vote.building') }}</td>
                  <template v-for="item in items">
                    <td>
                      <v-row>
                        <v-col cols="6" class="text-center text-h3 td-left">{{ item.building.toFixed(2) }}</v-col>
                        <v-col
                          cols="6"
                          class="text-center text-h3"
                        >{{ ((item.building/building_area)*100).toFixed(2) }}％</v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: vm => ({
    dialog_message: false,
    issue: { title: null },
    meeting: { meeting_time: null, urban_renewal: { name: null } },
    positive_count: 0,
    alternates_count: 0,
    result: {
      positive_vote: [],
      alternates_vote: []
    },
    owners: [],
    votes: [],
    options: [],
    building_relation: [],
    land_relation: [],
    voteRecord: [],
    building_area: 0,
    land_area: 0,
    issue_id: '',
    meeting_id: '',
    message: {
      title: "",
      text: ""
    },
    current_time: new Date(),
    voteStatus: {
      "invalid": 1,
      "valid": 2,
      "cancel": 3,
    }
  }),
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/voteResult/voting/";
        // this.issue.id +
        // "/voting/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Token " + this.token
        },
        params: {
          meeting_id: this.meeting_id,
          issue_id: this.issue_id
          //   page: page,
          //   page_size: itemsPerPage,
          //   task: this.id
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      //   this.totalItems = info.options.length;
      this.issue = info.issue;
      this.meeting = info.issue.meeting;
      this.positive_count = this.issue.positive_count;
      this.alternates_count = this.issue.alternates_count;
      this.voteRecord = [];
      this.building_area = 0;
      this.land_area = 0;
      this.owners = info.owners;
      this.votes = info.votes;
      this.options = info.options;
      this.getOwnerInfo();
      this.setVoteRecord();
    },
    setVoteRecord() {
      this.voteRecord = [];
      this.building_area = 0;
      this.land_area = 0;
      this.owners.forEach(owner => {
        this.building_area += this.getBuilding(owner);
        this.land_area += this.getLand(owner);
      });

      let result = [];
      this.votes.forEach(vote => {
        if (vote.vote_status == this.voteStatus.valid) {
          let owner = this.owners.find(item => {
            return item.id == vote.voter;
          });
          if (owner) {
            let building = this.getBuilding(owner);
            let land = this.getLand(owner);
            let option_list = vote.options_no_list.split(",");
            console.log(option_list)
            option_list.forEach(option_id => {
              if(option_id != ""){
                this.calculateVoteRecord(option_id, building, land);
              }
            });
          }
        }
      });
      this.voteRecord.sort(function(a, b) {
        return b.land - a.land;
      });
      result = this.voteRecord.slice(0, this.positive_count+this.alternates_count);
      let positive_tables = Math.ceil(this.positive_count/5)
      let alternates_tables = Math.ceil(this.alternates_count/5)
      let new_positive_vote = [];
      let new_alternates_vote = [];
      for(let i=0;i<positive_tables;i++){
        let end_index = 5+5*i;
        if(end_index>this.positive_count){
          end_index = this.positive_count
        }
        let new_item = result.slice(0+5*i, end_index)
        new_positive_vote.push(new_item)
      }
      for(let i=0;i<alternates_tables;i++){
        let end_index = this.positive_count+5+5*i;
        if(end_index>this.alternates_count){
          end_index = this.positive_count+this.alternates_count
        }
        let new_item = result.slice(this.positive_count+5*i, end_index)
        new_alternates_vote.push(new_item)
      }
      this.result.positive_vote = new_positive_vote
      this.result.alternates_vote = new_alternates_vote
    },
    getOwnerInfo(){
      let ownerInfo = this.issue.meeting.ownerInfo
      this.ownerInfo = JSON.parse(ownerInfo)
    },
    getBuilding(owner) {
      let owner_item = this.ownerInfo.find(element=>{return element.owner == owner.id})
      return owner_item.building_area
    },
    getLand(owner) {
      let owner_item = this.ownerInfo.find(element=>{return element.owner == owner.id})
      return owner_item.land_area
    },
    calculateVoteRecord(option_id, building, land) {
      let record = this.voteRecord.find(record => {
        return record.option_id == option_id;
      });
      if (record) {
        record.owner += 1;
        record.building += building;
        record.land += land;
      } else {
        let option = this.options.find(item => {
          return item.id == option_id;
        });
        let new_result = {
          owner: 1,
          building: building,
          land: land,
          option_id: option_id,
          option_name: option.option_name
        };
        this.voteRecord.push(new_result);
      }
    },
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      let new_vote = info.data;
      if (info.action == 1) {
        let vote = this.votes.find(item => {
          return item.id == new_vote.id;
        });
        if (vote) {
          vote.options_no_list = new_vote.options_no_list;
          vote.vote_status = new_vote.vote_status;
        } else {
          this.votes.push(new_vote);
        }
        this.setVoteRecord();
      }
    },
    getCurrentTime(){
      this.current_time = new Date()
    }
  },
  mounted() {
    this.$options.sockets.onmessage = this.receiveSocketData;
    this.setTitle(this.$i18n.t("vote.voteRecord"));
    this.issue_id = this.$route.query.item;
    this.meeting_id = this.$route.query.meeting;
    this.getApi();
    setInterval(()=>this.getCurrentTime(), 1000)
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
.table-title
    width: 5%

.record_table
    .v-data-table__wrapper
      thead
        &.positive_vote
          th
            background-color: #2699FB
            color: white!important
        &.alternates_vote
          th
            background-color: #4c5fab
            color: white!important
      th,td
          font-size: 16px
          border: 1px solid rgba(0,0,0, 0.6)
          border-top: 2px solid rgba(0,0,0, 0.6)
          .td-left
              border-right: 2px solid rgba(0,0,0, 0.6)
          &:first-child
            border-left: 2px solid rgba(0,0,0, 0.6)
          &:last-child
            border-right: 2px solid rgba(0,0,0, 0.6)
      tr
        &:last-child
          td
            border-bottom: 2px solid rgba(0,0,0, 0.6)
</style>
